<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer v-if="$route.path !== '/activity'"/>
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
import axios from "axios";

const wxConfig = () => {
  const { wx } = window;
  const url = encodeURIComponent('https://www.juzhen.cn/');

  wx && axios({
    url: `https://wechat.juzhen.cn/sign?url=${url}`,
    method: "post",
  }).then((data) => {
    if (!data) return;

    wx.config({
      debug: false,
      appId: data.app_id,
      timestamp: data.timestamp,
      nonceStr: data.nonce_str,
      signature: data.signature,
      jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
    });

    wx.ready(() => {
      const sharedata = {
        title: "让每个配送小哥享受美好生活",
        desc: "橙仕集团专注于解决配送伙伴的痛点,通过AI技术和工业能力研发科技产品，为配送小哥美好生活保驾护航",
        link: "http://www.juzhen.cn/",
        imgUrl: "http://www.juzhen.cn/wp-content/uploads/2020/08/logo.jpg",
      };

      wx.updateAppMessageShareData({
        ...sharedata
      });
      wx.updateTimelineShareData({
        ...sharedata
      });
    });
  });
}

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      els: [],
    }
  },
  watch: {
    $route() {
      // console.log(window.location.href);

      wxConfig();
    },
  },
  updated() {
    this.els = document.querySelectorAll('.ani');

    setTimeout(this.checkAni, 200);
  },
  mounted() {
    console.log(this.$route.path);
    const { addEventListener } = window;
    this.els = document.querySelectorAll('.ani');


    addEventListener('scroll', () => {
      this.checkAni();
    });

    setTimeout(this.checkAni, 200);

    wxConfig();

    // wx && wx.ready(function() {
    //   document.getElementById('J_wechatTxt').innerHTML="长按二维码关注官方微信";  
    // });
  },
  methods: {
    checkAni() {
      const { innerHeight: winHeight } = window;

      this.els.forEach((el) => {
        const { height, top } = el.getBoundingClientRect();
        const { className } = el;
        const cls = 'anied';
        const classNameArr = className.split(' ');
        const gap = height * 0.2;
        if (top < winHeight - gap && top + height > gap) {
          if (classNameArr.some((_cls) => _cls === cls)) return;

          classNameArr.push(cls);

          el.className = classNameArr.join(' ');
        } else {
          el.className = classNameArr.filter((_cls) => _cls !== cls).join(' ');
        }
      })
    }
  }
};
</script>