import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import axios from "axios";
import App from './App.vue'
import router from "./router";
import dayjs from "dayjs";
import ElementUI from 'element-ui';

import "@/assets/css/common.css";
// import "element-ui/lib/theme-chalk/loading.css";
import 'element-ui/lib/theme-chalk/index.css';

const checkMob = () => {
  const { userAgent } = window.navigator;
  const Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];

  return Agents.some((i)=>{
    return userAgent.includes(i)
  })
}

const checkSite = () => {
  const { host } = window.location;

  return host.includes('allcheer') ? 'allcheer' : 
    host.includes('cssmart') ? 'cssmart' : '';
}

axios.defaults.baseURL = process.env.VUE_APP_AXIOSURL + '/index.php/wp-json/wp/v2/';
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    const { data } = response;

    if (response.status === 200) {
      const pagination = {
        total: response.headers['x-wp-total'],
        pages: response.headers['x-wp-totalpages'],
      }

      if (pagination.total) {
        return Promise.resolve({
          data,
          pagination,
        });
      }

      return Promise.resolve(data);
    } else {
      return Promise.reject(data);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        case 401:
        case 403:
          break;
        // 404请求不存在
        case 404:
          console.log("网络请求不存在");
          break;
        // 其他错误，直接抛出错误提示
        default:
          console.log('response error:', JSON.stringify(error.response.data));
      }
      return Promise.reject(error.response);
    } else {
      return Promise.reject({
        message: '网络请求异常，请稍后重试！',
      });
    }
  }
);

Vue.config.productionTip = false
Vue.prototype.axios = axios;
Vue.prototype.isMob = checkMob();
Vue.prototype.siteType = checkSite();

Vue.use(VueLazyload);
Vue.use(ElementUI);

Vue.filter('removeHtml', (input = '') => {
  return input.replace(/<(?:.|\n)*?>/gm, '')
    .replace(/&rdquo;/g, '"')
    .replace(/&ldquo;/g, '"')
    .replace(/&mdash;/g, '-')
    .replace(/&nbsp;/g, ' ')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/<[\w\s"':=/]*/, '');
});
Vue.filter('dateFormat', (dateString = '') => {
  if (!dateString) return '';
  
  return dayjs(dateString).format('YYYY年MM月DD日');
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
