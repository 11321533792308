import Vue from "vue";
import VueRouter from "vue-router";

const Home = "Home.vue";
const Vehicles = "vehicles/Vehicles.vue";
const CS01 = "vehicles/CS01.vue";
const CS00 = "vehicles/CS00.vue";
const CSX2 = "vehicles/CSX2.vue";
const CSX5 = "vehicles/CSX5.vue";
const CSX7 = "vehicles/CSX7.vue";
const Chassis = "vehicles/Chassis.vue";
const Cafevan = "vehicles/Cafevan.vue";
const EC = "vehicles/EC.vue";

const Antiv = 'antiv/Antiv.vue';
const Sampling01 = 'antiv/Sampling01.vue';
const SamplingX2 = 'antiv/SamplingX2.vue';
const SVaaS = 'antiv/SVaaS.vue';
const CIIE = 'antiv/CIIE.vue';

const Technical = 'labs/Technical.vue';
const Ai = 'labs/Ai.vue';
const Open = 'labs/Open.vue';
const ZZ = 'labs/ZZ.vue';
const Water = 'labs/Water.vue';
const Zzai = 'labs/Zzai.vue';

const About = 'about/About.vue';
const Industrial = 'about/Industrial.vue';
// const Factory = 'about/Factory.vue';
const Partner = 'about/Partner.vue';
const Service = 'about/Service.vue';

const Jobs = 'join/Index.vue';
const JobDetail = 'join/Detail.vue';
const News = 'news/Index.vue';
const NewsDetail = 'news/Detail.vue';

// 亿点灵感
const VBD = 'vbd/Home.vue';
const VCU = 'vbd/VCU.vue';
const TBOX = 'vbd/TBOX.vue';
const KEY = 'vbd/KEY.vue';
const EDR = 'vbd/EDR.vue';
const MP = 'mp/index.vue';

// 双十一活动页面
// const  Activity = 'activity/index.vue'


Vue.use(VueRouter);

const routes = [
  {
    path: "/index",
    redirect: '/',
  },
  {
    path: "/",
    name: "index",
    meta:{
      title:"首页",
      style: 'static dark',
      themeColor: '#fff',
      bg:true
    },
    component: () => import(`../views/${Home}`),
  },
  {
    path: "/op",
    name: "Open",
    meta:{
      title:"开放平台",
      isPc: false,
      themeColor: '#0a0a0a'
    },
    component: () => import(`../views/${Open}`),
  },
  {
    path: "/0zai",
    name: "0zai",
    meta:{
      title:"0ZAI汽车",
      style: 'static dark',
      themeColor: '#000'
    },
    component: () => import(`../views/${Zzai}`),
  },
  {
    path: "/vehicles",
    name: "vehicles",
    meta:{
      title:"橙仕汽车",
      style: 'static dark'
    },
    component: () => import(`../views/${Vehicles}`),
  },
  {
    path: "/cs01",
    name: "cs01",
    meta:{
      title:"橙仕01",
      style: 'dark',
      themeColor: '#fff',
      bg:true
    },
    component: () => import(`../views/${CS01}`),
  },
  {
    path: "/ec",
    name: "ec",
    meta:{
      title:"橙仕01特别版社区电商",
      style: 'dark',
      themeColor: '#dde4e4',
    },
    component: () => import(`../views/${EC}`),
  },
  {
    path: "/cs00",
    name: "cs00",
    meta:{
      title:"橙仕00",
    },
    component: () => import(`../views/${CS00}`),
  },
  {
    path: "/csx2",
    name: "csX2",
    meta:{
      title:"橙仕X2",
      style: 'static dark',
      themeColor: '#fff',
      bg:true
    },
    component: () => import(`../views/${CSX2}`),
  },

  {
    path: "/csx5",
    name: "csX2",
    meta:{
      title:"橙仕X5",
      style: 'static dark',
      themeColor: '#fff',
      bg:true
    },
    component: () => import(`../views/${CSX5}`),
  },
  {
    path: "/csx7",
    name: "csX7",
    meta:{
      title:"橙仕X7",
      bg:true
    },
    component: () => import(`../views/${CSX7}`),
  },
  {
    path: "/chassis",
    name: "chassis",
    meta:{
      title:"橙仕X7",
    },
    component: () => import(`../views/${Chassis}`),
  },
  {
    path: "/cafevan",
    name: "Cafevan",
    meta:{
      title:"魔方咖啡车",
    },
    component: () => import(`../views/${Cafevan}`),
  },
  {
    path: "/ai",
    name: "ai",
    meta:{
      title:"橙仕大脑",
      style: 'light',
    },
    component: () => import(`../views/${Ai}`),
  },
  {
    path: "/industrial",
    name: "industrial",
    meta:{
      title:"橙仕工业",
      style: 'light',
    },
    component: () => import(`../views/${Industrial}`),
  },
  // {
  //   path: "/factory",
  //   name: "Factory",
  //   meta:{
  //     title:"矩阵工厂",
  //     style: 'light',
  //   },
  //   component: () => import(`../views/${Factory}`),
  // },
  {
    path: "/technical",
    name: "Technical",
    meta:{
      title:"实验室",
      style: 'light',
    },
    component: () => import(`../views/${Technical}`),
  },
  {
    path: "/about",
    name: "About",
    meta:{
      title:"公司简介",
    },
    component: () => import(`../views/${About}`),
  },
  {
    path: "/partner",
    name: "Partner",
    meta:{
      title:"橙仕合伙人",
    },
    component: () => import(`../views/${Partner}`),
  },
  {
    path: "/service",
    name: "Service",
    meta:{
      title:"售后服务",
    },
    component: () => import(`../views/${Service}`),
  },
  {
    path: "/join",
    name: "Join",
    meta:{
      title:"人才招聘",
    },
    component: () => import(`../views/${Jobs}`),
  },
  {
    path: "/join/:id",
    name: "JobDetail",
    meta:{
      title:"职位详情",
      style: 'static brand mStatic mDark'
    },
    component: () => import(`../views/${JobDetail}`),
  },
  {
    path: "/news",
    name: "News",
    meta:{
      title:"媒体资讯",
      style: 'static brand mStatic mDark',
      design:true
    },
    component: () => import(`../views/${News}`),
  },
  {
    path: "/news/:id",
    name: "NewsDetail",
    meta:{
      title:"资讯详情",
      style: 'static brand mStatic mDark',
      design:true
    },
    component: () => import(`../views/${NewsDetail}`),
  },
  {
    path: "/antiv",
    name: "Antiv",
    meta:{
      title:"橙仕安特威",
    },
    component: () => import(`../views/${Antiv}`),
  },
  {
    path: "/zz",
    name: "ZZ",
    meta:{
      title:"智能双足机器人",
      style: 'static dark',
      themeColor: '#fff'
    },
    component: () => import(`../views/${ZZ}`),
  },
  {
    path: "/sampling01",
    name: "Sampling01",
    meta:{
      title:"智能健康服务车01",
    },
    component: () => import(`../views/${Sampling01}`),
  },
  {
    path: "/samplingX2",
    name: "SamplingX2",
    meta:{
      title:"智能健康服务车X2",
    },
    component: () => import(`../views/${SamplingX2}`),
  },
  {
    path: "/svaas",
    name: "SVaaS",
    meta:{
      title:"SVaaS解决方案",
    },
    component: () => import(`../views/${SVaaS}`),
  },
  {
    path: "/water",
    name: "Water",
    meta:{
      title:"水上机器人",
      style: 'static dark',
      themeColor: '#fff'
    },
    component: () => import(`../views/${Water}`),
  },
  {
    path: "/ciie",
    name: "CIIE",
    meta:{
      title:"中国国际进口博览会",
    },
    component: () => import(`../views/${CIIE}`),
  },
  {
    path: "/vbd",
    name: "vbd",
    meta:{
      title:"亿点灵感",
    },
    component: () => import(`../views/${VBD}`),
  },
  {
    path: "/vcu",
    name: "VCU",
    meta:{
      title:"VCU",
    },
    component: () => import(`../views/${VCU}`),
  },
  {
    path: "/tbox",
    name: "TBOX",
    meta:{
      title:"T-Box",
    },
    component: () => import(`../views/${TBOX}`),
  },
  {
    path: "/ydKey",
    name: "KEY",
    meta:{
      title:"数据钥匙",
    },
    component: () => import(`../views/${KEY}`),
  },
  {
    path: "/edr",
    name: "EDR",
    meta:{
      title:"EDR",
    },
    component: () => import(`../views/${EDR}`),
  },
  {
    path: "/StvehicleUr",
    name: "mp",
    meta:{
      title:"橙仕车主",
      mp: true,
    },
    component: () => import(`../views/${MP}`),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});
// let init = window.location.pathname !== '/';

// router.beforeEach((to, from, next) => {
//   if (!init && to.name === 'index') {
//     init = true;

//     next({ name: 'cs01' })
//   } else {
//     next()
//   }
// });

router.beforeEach((to, from, next) => {
  const { meta = {} } = to;
  const el = document.getElementById('THEMECOLOR');

  el && el.setAttribute('content', meta.themeColor);

  next()
});

export default router;
